import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import NextSections from '../components/NextSections'
import FullView from '../components/DONUTFullView'
import GithubLink from '../components/GithubLink'

// import images
import mockup from '../images/checkout-page/mockup.jpg'
import desktop from '../images/checkout-page/donut_desktop.jpg'
import mobile from '../images/checkout-page/donut_mobile.jpg'

export default () => (
    <Project
        title={'daily ui: checkout page'}
            date={'2019'}
            position={'Personal Project'}
            employer={''}
            brief={[
                'I am taking part in the Daily UI Challenge – a daily interface design prompt. However,  instead of just creating the UI, I wanted to take it a step further and code the design as well.',
                'The challenge was to create a checkout page for an online store. I decided to design the checkout page of a donut shop. The page had to represent the imaginary brand while being functional and offer the information customers would need before they placed their order.',
                'I developed a simple layout, using a pastel color-pallet and minimal imagery in order to ensure the information remained prominent. A split-screen design allows for an order summary to be displayed on the left on desktop (pulled down on mobile) and payment details on the right. The summary allows customers to confirm what they ordered while keeping the payment information at the forefront, especially on mobile. The result has a whimsical feel, appropriate for a donut shop, with functionality that can be displayed on any screen size.'
            ]}
            skills={[
                'XD',
                'Illustrator',
                'Branding',
                'HTML',
                'CSS',
                'Javascript'
            ]}
    >
        <FullWidthImage src={mockup} alt={'Computer with design on it'} />
        <FullView 
            desktop={desktop}
            mobile={mobile}
            backgroundColor={'#EEEEEE'}
            codeLink={'https://sophievakalis.github.io/daily-ui-checkout-page/'}
            color={'#E85588'}
        />
        <GithubLink background={true}/>
        <Padding size={'s'}/>
        <NextSections
            previous={'make-me-chic-email'}
            next={'visual-narratives'}
        />
    </Project>
)