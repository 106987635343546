import React from 'react'

//import styles
import styles from './DONUTFullView.module.css'

//import components
import Padding from './Padding'
import Line from './Line'

const FullView = ({desktop, mobile, backgroundColor, desktopLink, mobileLink, codeLink, color}) => {
    const buttonColor = {
        background: color
    }
    return(
        <div style={{background:backgroundColor}}>
            <Padding />
            <div className={styles.container}>
                <img src={desktop} alt={'Desktop version'} />
                <img src={mobile} alt={'Mobile version'} />
            </div>
            <Padding />
            <div className={styles.linkContainer}>
                <Line color={'black'}/>
                <h3>CHECK OUT THE PROTOTYPE</h3>
                <div className={styles.buttonContainer}>
                    {desktopLink ? 
                        <a
                        href={desktopLink}
                        className={styles.linkButton}
                        style={buttonColor}
                        target='_blank'
                        rel="noopener noreferrer"
                        >
                            Desktop
                        </a>:
                        null
                    }
                    {mobileLink ? 
                        <a
                        href={mobileLink}
                        className={styles.linkButton}
                        style={buttonColor}
                        target='_blank'
                        rel="noopener noreferrer"
                        >
                            Mobile
                        </a>:
                        null
                    }
                    {codeLink ?
                        <a
                        href={codeLink}
                        className={styles.linkButton}
                        style={buttonColor}
                        target='_blank'
                        rel="noopener noreferrer"
                        >
                            HTML/CSS
                        </a>:
                        null
                    }
                </div>
            </div>
            <Padding />
        </div>
    )}

    export default FullView